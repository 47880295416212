























































































































import merge from "../utils/merge";
import defaultComponent from "../utils/defaultComponent";
import DataContainer from "../common/DataContainer";

export default defaultComponent.apply({
  props: [],
  data() {
    return {
      contentHTML: "",
      buttonContainers: {},
      contentContainer: new DataContainer(),
      hideBottomActions: false,
    };
  },
  watch: {
    model: {
      handler(val) {
        if (val.value) {
          this.hideBottomActions = false;
        }
        if (!val.value) {
          this.emit("xHidden");
        }
      },
      deep: true,
    },
  },
  computed: {
    titleHTML() {
      if (typeof this.options.content.toolbar.title === "function") {
        return this.options.content.toolbar.title(this);
      }
      return this.options.content.toolbar.title;
    },
    subTitleHTML() {
      if (typeof this.options.content.toolbar.subTitle === "function") {
        return this.options.content.toolbar.subTitle(this);
      }
      return this.options.content.toolbar.subTitle;
    },
    textHTML() {
      if (this.options.content.content.text) {
        if (typeof this.options.content.content.text.html === "function") {
          return this.options.content.content.text.html(this);
        }
        return this.options.content.content.text.html;
      } else {
        return "";
      }
    },
    textText() {
      if (this.options.content.content.text) {
        if (typeof this.options.content.content.text.text === "function") {
          return this.options.content.content.text.text(this);
        }
        return this.options.content.content.text.text;
      } else {
        return "";
      }
    },
  },
  methods: {
    initialize() {
      if (this.options.content.cancelDisabled) {
        delete this.options.content.buttons.cancel;
      }

      for (const key in this.options.content.buttons) {
        let button = this.options.content.buttons[key];
        if (!button.ignoreDefault) {
          button = merge.clone(this.options.content.template.button, button);
        }
        this.options.content.buttons[key] = button;

        this.buttonContainers[key] = new DataContainer();
      }
      if (this.options && this.options.makeAttrs) {
        this.options = this.options.makeAttrs(this.options, this);
      }
    },
    show() {
      this.model.value = true;
    },
    hide() {
      this.model.value = false;
    },
    getButton(key) {
      const buttonContainer = this.buttonContainers[key];
      if (!buttonContainer) {
        return null;
      }
      return buttonContainer.value;
    },
    setHideBottomActions(value = false) {
      this.hideBottomActions = value;
    },
    content() {
      return this.contentContainer.value;
    },
  },
  extraOptions: {
    forwardStates: {
      xModel: ["model", false],
    },
    defaultClass: "dialog",
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({key:_vm.model.value.toString(),scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData)]}}})],null,true),model:{value:(_vm.model.value),callback:function ($$v) {_vm.$set(_vm.model, "value", $$v)},expression:"model.value"}},'v-dialog',_vm.options.attrs,false),_vm.options.on),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})}),_c('v-card',_vm._b({style:({ 'padding-left': _vm.$vuetify.breakpoint.mobile ? 0: undefined, 'padding-right': _vm.$vuetify.breakpoint.mobile ? 0: undefined,  }),attrs:{"dense":_vm.$vuetify.breakpoint.mobile}},'v-card',_vm.options.content.card.attrs,false),[_c('v-toolbar',_vm._b({staticClass:"d-flex"},'v-toolbar',_vm.options.content.toolbar.attrs,false),[(_vm.titleHTML)?_c('v-toolbar-title',{domProps:{"innerHTML":_vm._s(_vm.titleHTML)}}):_vm._e(),(_vm.subTitleHTML)?[_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),_c('small',{staticClass:"subheading",domProps:{"innerHTML":_vm._s(_vm.subTitleHTML)}})]:_vm._e(),(
          _vm.options.content.toolbar && _vm.options.content.toolbar.actionButtons
        )?_c('div',{staticClass:"d-flex"},[_c('v-spacer'),_vm._l((_vm.options.content.toolbar.actionButtons),function(btn,key){return [(
              !btn.ext ||
              (!btn.ext.hidden &&
                (!btn.ext.condition || btn.ext.condition(_vm.self)))
            )?_c('x-btn',_vm._b({key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-default-class":"dataTable.content.template.topActionButton"}},'x-btn',btn.ext && btn.ext.makeAttrs && btn.ext.makeAttrs(_vm.self),false)):_vm._e()]})],2):_vm._e()],2),_c('v-card-text',[_c('v-container',{directives:[{name:"x-autofocus",rawName:"v-x-autofocus",value:(_vm.options.content.autofocus),expression:"options.content.autofocus"}]},[(_vm.textHTML)?_c('p',_vm._b({domProps:{"innerHTML":_vm._s(_vm.textHTML)}},'p',_vm.options.content.content.attrs,false)):_vm._e(),(_vm.textText)?_c('p',_vm._b({domProps:{"innerHTML":_vm._s(_vm.textText)}},'p',_vm.options.content.content.attrs,false)):_vm._e(),(_vm.options.content.content.type)?_c(_vm.options.content.content.type,_vm._b({tag:"component",attrs:{"x-container":_vm.contentContainer,"x-context":_vm.self}},'component',
            _vm.options.content.content.makeAttrs
              ? _vm.options.content.content.makeAttrs(
                  _vm.options.content.content.attrs,
                  _vm.self
                )
              : _vm.options.content.content.attrs
          ,false)):_vm._e()],1)],1),(
        (!_vm.options.content.content.type || _vm.options.content.contentIsNotDefaultComponent || _vm.contentContainer.value) &&
        Object.keys(_vm.options.content.buttons).length !== 0
      )?_c('v-card-actions',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideBottomActions),expression:"!hideBottomActions"}]},[_c('div',{staticClass:"flex-grow-1"}),_vm._l((_vm.options.content.buttons),function(btn,key){return [(
            !btn.ext ||
            (!btn.ext.hidden &&
              (!btn.ext.condition || btn.ext.condition(_vm.self)))
          )?_c('x-basic-btn',{key:key,attrs:{"x-options":btn,"x-context":_vm.self,"x-container":_vm.buttonContainers[key]}}):_vm._e()]})],2):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }